<template>
    <CCard>
        <CCardHeader class="page-header">
            <strong> {{ $t('common.menu.settings.settings') }}</strong>
            <back-button></back-button>
        </CCardHeader>
        <CCardBody>
            <b-row>
                <b-col cols="2" v-for="item in this.getIitems()">
                    <b-button @click="follow(item.to)"
                              block
                              class="btn btn-rounded m-1 p-1"
                              :variant="item.name == 'general' ? 'danger' : 'light'"
                              style="font-size: 1.2rem;height:110px">
                        {{ item.label ? item.label : $t('common.menu.settings.' + item.name) }}
                        <font-awesome-icon class="ml-2" v-if="item.icon" :icon="['fa', item.icon]"/>
                    </b-button>
                </b-col>
            </b-row>
        </CCardBody>
    </CCard>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'SettingsGlobal',
    data() {
        return {
            items: [
                {
                    name: 'general',
                    module: 'settings',
                    to: '/settings/general',
                    icon: 'cogs',
                },
                {
                    name: 'documents',
                    module: 'settings',
                    to: '/settings/documents',
                    icon: 'file-invoice',
                },
                {
                    name: 'attributes',
                    module: 'settings',
                    to: '/settings/attributes',
                    icon: 'braille',
                },
                {
                    name: 'shipping',
                    module: 'settings',
                    to: '/settings/shipping',
                    icon: 'dolly-flatbed',
                },
                {
                    name: 'stocks',
                    module: 'settings',
                    to: '/settings/stocks',
                    icon: 'dolly',
                },
                {
                    name: 'users',
                    module: 'settings',
                    to: '/settings/users',
                    icon: 'user',
                },
                {
                    name: 'emails',
                    module: 'settings',
                    to: '/settings/emails',
                    icon: 'envelope',
                },
                {
                    name: 'statuses',
                    module: 'settings',
                    to: '/settings/statuses',
                    icon: 'history',
                },
                {
                    name: 'paymentmethods',
                    module: 'settings',
                    to: '/settings/paymentmethods',
                    icon: 'euro-sign',
                },
                {
                    name: 'crm',
                    module: 'crm',
                    to: '/settings/crm',
                    icon: 'heart',
                },
                {
                    name: 'workflows',
                    module: 'settings',
                    to: '/settings/workflows',
                    icon: 'map-signs',
                },
                {
                    name: 'kb',
                    module: 'settings',
                    to: '/settings/kb',
                    icon: 'certificate',
                },
                {
                    name: 'categories',
                    module: 'settings',
                    to: '/settings/categories',
                    icon: 'stream',
                },
                {
                    name: 'languages',
                    module: 'settings',
                    to: '/settings/languages',
                    icon: 'language',
                },
                {
                    name: 'modules',
                    module: 'settings',
                    to: '/settings/modules',
                    icon: 'puzzle-piece',
                },
                {
                    name: 'articles',
                    module: 'settings',
                    to: '/settings/articles',
                    icon: 'barcode',
                },
                {
                    name: 'channels',
                    module: 'settings',
                    to: '/settings/channels',
                    icon: 'boxes',
                },
                {
                    name: 'custom_fields',
                    module: 'custom-fields',
                    to: '/custom-fields',
                    icon: 'server',
                },
            ],
            loading: true,
            form: {},
            labelPrefix: 'settings.global.label.',
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getPlugins', 'getCommonData']),
        getIitems() {
            let menu = this.items;

            let newItems = [];
            this.getPlugins().forEach(pluginName => {
                const plugin = window[pluginName] || null;
                if (plugin && plugin.methods.getMenuStructure) {
                    plugin.methods.getMenuStructure(this.getCommonData())
                        .forEach((componentStructure, index) => {
                            if (componentStructure.name == 'settings' || componentStructure.name == 'settings-global') {
                                if (componentStructure.sub_menu) {
                                    componentStructure.sub_menu.forEach(item => {
                                        newItems.push(item)
                                    })
                                }
                            }
                        })
                }
            })
            menu = menu.concat(newItems);

            let subMenuSorted = Object.keys(menu).sort(function (a, b) {
                return menu[a].name.localeCompare(menu[b].name)
            })
            let subMenuItems = []
            subMenuSorted.forEach(key => {
                subMenuItems.push(menu[key])
            })

            return subMenuItems;
        },
        shown() {
        },
        follow(to) {
            this.$router.push({path: to})
        },
    },
    mounted() {
        this.shown()
    }
}
</script>